import * as React from "react";
import { Admin, Resource, fetchUtils, Layout, AppBar, TitlePortal, usePermissions } from 'react-admin';
import PersonIcon from '@mui/icons-material/Person';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CategoryIcon from '@mui/icons-material/Category';
import SearchIcon from '@mui/icons-material/Search';
import authProvider from './provider/auth';
import simpleRestProvider from 'ra-data-simple-rest';
import { MemberList } from './component/Member/List'
import { MemberEdit } from "./component/Member/Edit";
import { TransactionList } from "./component/Transaction/List";
import { ClaimList } from "./component/Claim/List";
import { ClaimEdit } from "./component/Claim/Edit";
import { ProductEdit } from "./component/Products/Edit";
import { ProductsList } from "./component/Products/List";
import { ActivityList } from "./component/Activity/List";
import { ActivityEdit } from "./component/Activity/Edit";
import { ActivityCreate } from "./component/Activity/Create";
import { LookupsList } from "./component/Lookups/List";
import { LookupsCreate } from "./component/Lookups/Create";
import { LookupsEdit } from "./component/Lookups/Edit";
import { ProductCreate } from "./component/Products/Create";

const httpClient = (url, options = {}) => {
  const credential = JSON.parse(localStorage.getItem('credential'));
  options.headers = new Headers({ Authorization: `Bearer ${credential?.token}` });
  return fetchUtils.fetchJson(url, options);
}

const dataProvider = simpleRestProvider(process.env.REACT_APP_API, httpClient);

const customProvider = {
  ...dataProvider,
  create: (resource, params) => {
    if (resource === 'activity') {
      let formData = new FormData();
      formData.append('name', params.data.name)
      formData.append('type', params.data.type)
      formData.append('details', params.data.details)
      formData.append('thumbnail', params.data.thumbnail.rawFile)
      formData.append('header', params.data.header.rawFile)
      formData.append('raffle', params.data.raffle)
      formData.append('price', params.data.price)
      formData.append('open', params.data.open)
      return httpClient(`${process.env.REACT_APP_API}/${resource}`, {
        method: 'POST',
        body: formData
      }).then(({ json }) => ({
        data: { ...params.data, id: json._id }
      }))
    }
    else if (resource === 'products') {
      let formData = new FormData();
      formData.append('group', params.data.group)
      formData.append('name', params.data.name)
      formData.append('tagline', params.data.tagline)
      formData.append('price', params.data.price)
      formData.append('details', params.data.details)
      formData.append('type', params.data.type)
      formData.append('disabled', params.data.disabled)
      formData.append('thumbnail', params.data.thumbnail.rawFile)
      return httpClient(`${process.env.REACT_APP_API}/${resource}`, {
        method: 'POST',
        body: formData
      }).then(({ json }) => ({
        data: { ...params.data, id: json._id }
      }))
    } else {
      return dataProvider.create(resource, params);
    }
  }
}
const CustomLayout = (props) => <Layout {...props} appBar={CustomAppBar} />

const CustomAppBar = (props) => {
  const { permissions } = usePermissions()
  console.log(permissions)
  return <AppBar {...props}>
    <TitlePortal />
    <div style={{ textTransform: 'capitalize' }}>{permissions}</div>
  </AppBar>
}


const App = () => (
  <Admin requireAuth layout={CustomLayout} initialState={{ admin: { ui: { sidebarOpen: false } } }} disableTelemetry authProvider={authProvider} dataProvider={customProvider}>
    {permissions => <>
      {permissions === 'admin' &&
        <Resource name="members" icon={PersonIcon} list={MemberList} edit={MemberEdit} />}
      {(permissions === 'admin' || permissions === 'agent' || permissions === 'supcall') &&
        <Resource name="backendLookup" options={{ label: 'Lookups' }} icon={SearchIcon} list={LookupsList} edit={LookupsEdit} create={LookupsCreate} />}
      {(permissions === 'admin' || permissions === 'agent') &&
        <Resource name="transactions" icon={ReceiptIcon} list={TransactionList} />}
      {(permissions === 'admin' || permissions === 'agent' || permissions === 'supcall') &&
        <Resource name="claims" icon={ShoppingCartIcon} list={ClaimList} edit={ClaimEdit} />}
      {(permissions === 'admin' || permissions === 'agent' || permissions === 'supmkt') &&
        <Resource name="products" icon={CategoryIcon} list={ProductsList} edit={ProductEdit} create={ProductCreate} />}
      {(permissions === 'admin' || permissions === 'agent' || permissions === 'supmkt') &&
        <Resource name="activity" icon={MonetizationOnIcon} list={ActivityList} edit={ActivityEdit} create={ActivityCreate} />}
      {(permissions === 'admin' || permissions === 'agent') &&
        <Resource name="raffle" />}
    </>}
  </Admin>
);

export default App;