import * as React from "react";
import { Pagination, Edit, TextInput, BooleanInput, NumberInput, TabbedForm, FormTab, DateField, TextField, Datagrid, List, useEditContext } from 'react-admin';
// ImageInput, ImageField
import AddManyButton from "../Member/addManyButton";
export const ActivityEdit = (props) => (
    <Edit {...props}>
        <TabbedForm>
            <FormTab label="Activity">
                <TextInput disabled source="name" style={{ float: "left", marginLeft: '20px', width: "30%" }} />
                <NumberInput disabled source="participants" style={{ marginLeft: '20px', width: "30%" }} />
                <TextInput disabled source="type" style={{ marginLeft: '20px', width: "30%" }} />
                <TextInput source="tagline" style={{ marginLeft: '20px', width: "30%" }} />
                <TextInput multiline source="details" style={{ marginLeft: '20px' }} />
                <BooleanInput source="hide" style={{ float: "left", paddingLeft: '30px' }} />
                <BooleanInput disabled source="raffle" style={{ float: "left", paddingLeft: '30px' }} />
                <CanBeDisableBooleanInput source="open" style={{ paddingLeft: '30px', width: "30%" }} />
                <CanBeDisableNumberInput source="price" style={{ paddingLeft: '30px', width: "30%" }} />
                <DateField disabled source="created" showTime style={{ marginLeft: '20px' }} />
                <DateField disabled source="updated" showTime style={{ marginLeft: '20px' }} />
                {/* <ImageInput source="image" accept="image/*">
                    <PreviewImage source="src" />
                </ImageInput>
                <ImageInput source="detailImg" accept="image/*">
                    <PreviewImage source="src" />
                </ImageInput> */}
            </FormTab>
            <FormTab label="Participants">
                <ReferenceList />
            </FormTab>
        </TabbedForm>
    </Edit>
);

const ReferenceList = () => {
    const record = useEditContext();
    const customProps = {
        basePath: "/raffle",
        hasCreate: false,
        hasEdit: true,
        hasList: true,
        hasShow: false,
        options: {},
        permissions: undefined,
        resource: "raffle"
    }
    return (<List {...customProps} filter={{ activity: record.record.id }} style={{ width: '100%' }}
        pagination={<CustomPagination />}>
        <Datagrid bulkActionButtons={<AddManyButton />}>
            <DateField showTime source="created" />
            <TextField label="Raffle" source="name" />
            <TextField label="User" source="member.userId" />
            <TextField label="Email" source="member.email" />
            <TextField label="PhoneNumber" source="member.phoneNumber" />
            <TextField label='Class' source="member.class" />
            <TextField label='Price' source="price" />
        </Datagrid>
    </List>)
}


// const PreviewImage = ({ record, source }) => {
//     if (typeof (record) == "string") {
//         record = {
//             [source]: record
//         }
//     }
//     return <ImageField record={record} source={source} />
// }


const CanBeDisableBooleanInput = props => <BooleanInput {...props} disabled={!!(props.record?.raffle)} />
const CanBeDisableNumberInput = props => <NumberInput {...props} disabled={!!(props.record?.raffle)} />

const CustomPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 300]} />