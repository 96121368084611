import * as React from "react";
import { List, Datagrid, TextField, BooleanField, Filter, SelectInput, NumberField } from 'react-admin';
const ProductFilter = (props) => (
    <Filter {...props}>
        <SelectInput source="group" choices={[
                { id: 'lifestyle', name: 'Lifestyle' },
                { id: 'gadget', name: 'Gadget' },
                { id: 'gift voucher', name: 'Gift Voucher' },
                { id: 'entertainment', name: 'Entertainment' },
                { id: 'others', name: 'Others' }
            ]} />
        <SelectInput source="disabled" choices={[
            { id: true, name: 'TRUE' },
            { id: false, name: 'FALSE' },
        ]} />
        <SelectInput source="type" choices={[
            { id: 'email', name: 'Email' },
            { id: 'address', name: 'Address' },
        ]} />
    </Filter>
);
export const ProductsList = (props) => {
    return (
        <List {...props} filters={<ProductFilter />} bulkActionButtons={false} sort={{ field: 'updated', order: 'DESC' }}>
            <Datagrid rowClick="edit">
                <TextField source="group" />
                <TextField source="name" />
                <TextField source="price" />
                <TextField source="tagline" />
                {/* <TextField source="details" /> */}
                <TextField source="type" />
                <NumberField source="bought" />
                <BooleanField source="disabled" />
            </Datagrid>
        </List>
    );
};