import * as React from "react";
import { Create, TextInput, BooleanInput, ImageInput, ImageField, SimpleForm, SelectInput, required, NumberInput } from 'react-admin';
// import { useWatch } from 'react-hook-form'

export const ProductCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <SelectInput source="group" validate={required()} choices={[
                { id: 'lifestyle', name: 'Lifestyle' },
                { id: 'gadget', name: 'Gadget' },
                { id: 'gift voucher', name: 'Gift Voucher' },
                { id: 'entertainment', name: 'Entertainment' },
                { id: 'others', name: 'Others' },
                
            ]} />
            <TextInput source="name" validate={required()}/>
            <TextInput multiline source="tagline" validate={required()}/>
            <NumberInput source="price" validate={required()}/>
            <TextInput multiline source="details" validate={required()}/>
            <SelectInput source="type" validate={required()} choices={[
                { id: 'address', name: 'Address' },
                { id: 'email', name: 'Email' }
            ]} />
            <ImageInput source="thumbnail" accept="image/*" validate={required()}>
                <ImageField source="src" />
            </ImageInput>
            <BooleanInput source="disabled" initialValue={true}/>
        </SimpleForm>
    </Create>
);