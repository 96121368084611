import * as React from "react";
import { Pagination, useGetIdentity, ExportButton, List, Datagrid, TextInput, TextField, DateField, SelectField, Filter, SelectInput, TopToolbar, BooleanField } from 'react-admin';
import ImportButton from './importLookup'
import AddManyButton from "./addManyButton";
import AddMultipleBtn from "../Lookups/AddMultiple";
import SendMessageButton from "./sendMessage";
const MemberFilter = (props) => (
    <Filter {...props}>
        <TextInput source="userId"></TextInput>
        <SelectInput source="class" choices={[
            { id: 'Member', name: 'Member' },
            { id: 'Professional', name: 'Professional' },
            { id: 'Master', name: 'Master' },
            { id: 'Grandmaster', name: 'Grandmaster' },
        ]} />
        <SelectInput label="Birth Months" source="bmonths" choices={[
            { id: '1', name: 'มกรา' },
            { id: '2', name: 'กุมภา' },
            { id: '3', name: 'มีนา' },
            { id: '4', name: 'เมษา' },
            { id: '5', name: 'พฤษภา' },
            { id: '6', name: 'มิถุนา' },
            { id: '7', name: 'กรกฎา' },
            { id: '8', name: 'สิงหา' },
            { id: '9', name: 'กันยา' },
            { id: '10', name: 'ตุลา' },
            { id: '11', name: 'พฤศจิกา' },
            { id: '12', name: 'ธันวา' },
        ]} />
    </Filter>
);

const Actions = (props) => (
    <TopToolbar>
        <ImportButton />
        <AddMultipleBtn />
        {React.cloneElement(props.filters, { context: 'button' })}
        {props.identity ==='admin2@365grands.com' && <ExportButton maxResults={99999} />}
    </TopToolbar>
);

const BulkActions = props => (
    <>
        <AddManyButton {...props} />
        <SendMessageButton {...props} />
    </>
)

export const MemberList = (props) => {
    const {data:identity} = useGetIdentity()
    return (
        <List {...props} empty={false} actions={<Actions identity={identity}/>} exporter={false} filters={<MemberFilter />} sort={{ field: 'created', order: 'DESC' }}
            pagination={<CustomPagination />}>
            <Datagrid rowClick="edit" bulkActionButtons={<BulkActions />}>
                <DateField source="created" showTime />
                <TextField source="userId" />
                <TextField source="email" />
                <TextField label='Line' source="lineName" />
                <DateField label="Date Of Birth" source="dob" />
                <BooleanField source="disabled" />
                <TextField source="phoneNumber" />
                <TextField source="address" />
                <TextField source="coins" />
                <SelectField source="class" choices={[
                    { id: 'Member', name: 'Member' },
                    { id: 'Professional', name: 'Professional' },
                    { id: 'Master', name: 'Master' },
                    { id: 'Grandmaster', name: 'Grandmaster' },
                ]} />
            </Datagrid>
        </List>
    );
};

const CustomPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 300]} />